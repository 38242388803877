import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import FormControl from '@mui/material/FormControl';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const AtualizarNRs = () => {
    const [funcionario, setFuncionario] = useState(null);
    const [listaFuncionarios, setListaFuncionarios] = useState([]);
    const [funcao, setFuncao] = useState('');
    const [local, setLocal] = useState('');
    const [dataNR10, setDataNR10] = useState(null);
    const [validadeNR10, setValidadeNR10] = useState(null);
    const [errorNR10, setErrorNR10] = useState(false);
    const [dataNR35, setDataNR35] = useState(null);
    const [validadeNR35, setValidadeNR35] = useState(null);
    const [errorNR35, setErrorNR35] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    const filter = createFilterOptions();

    useEffect(() => {
        async function buscarFuncionarios () {
            const response = await fetchFlask('buscarFuncionarios');
            if (response.status !== 'error') {
                setListaFuncionarios(response.dados);
            } else {
                handleAlerta(response);
            }
        };
        buscarFuncionarios();
    }, []);

    async function submitAtualizarNRs (e) {
        e.preventDefault();

        let dataNR10Str = null;
        let validadeNR10Str = null;
        if ((dataNR10 && !validadeNR10) || (!dataNR10 && validadeNR10)) {
            setErrorNR10(true);
            return;
        } else if (dataNR10 && validadeNR10) {
            dataNR10Str = dataNR10.toISOString().slice(0, 10);
            validadeNR10Str = validadeNR10.toISOString().slice(0, 10);
        }
        setErrorNR10(false);

        let dataNR35Str = null;
        let validadeNR35Str = null;
        if ((dataNR35 && !validadeNR35) || (!dataNR35 && validadeNR35)) {
            setErrorNR35(true);
            return;
        } else if (dataNR35 && validadeNR35) {
            dataNR35Str = dataNR35.toISOString().slice(0, 10);
            validadeNR35Str = validadeNR35.toISOString().slice(0, 10);
        }
        setErrorNR35(false);

        if (!listaFuncionarios.includes(funcionario)) {
            setListaFuncionarios([...listaFuncionarios, funcionario]);
        }

        const body = JSON.stringify({
            funcionario,
            funcao,
            local,
            dataNR10: dataNR10Str,
            validadeNR10: validadeNR10Str,
            dataNR35: dataNR35Str,
            validadeNR35: validadeNR35Str
        });
        const response = await fetchFlask('atualizarNRs', body);
        handleAlerta(response);
        if (response.status === 'success') {
            setFuncao('');
            setLocal('');
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Atualizar e Adicionar NRs</Typography>

            <form method='POST' onSubmit={submitAtualizarNRs}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <Autocomplete
                        sx={{ width: '500px' }}
                        value={funcionario}
                        options={listaFuncionarios}
                        onChange={(e, value) => setFuncionario(value)}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push(inputValue);
                            }

                            return filtered;
                          }}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Funcionários"
                            required
                            />
                        )}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Função'
                        value={funcao}
                        onChange={(e) => setFuncao(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Lotação Atual'
                        value={local}
                        onChange={(e) => setLocal(e.target.value)}
                    />
                </FormControl>

                <Box>
                    <FormControl sx={{ marginTop: '20px', marginRight: '10px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker label='Data NR-10'
                                minDate={new Date('2023-01-02')}
                                onChange={(value) => setDataNR10(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ marginTop: '20px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker label='Validade NR-10'
                                minDate={new Date('2023-01-02')}
                                onChange={(value) => setValidadeNR10(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    {errorNR10 && (
                        <Box sx={{ marginLeft: '25px' }}>
                            <Typography variant='subtitle1' color='red'>
                                Por favor selecione ambas as datas!
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box>
                    <FormControl sx={{ marginTop: '20px', marginRight: '10px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker label='Data NR-35'
                                minDate={new Date('2023-01-02')}
                                onChange={(value) => setDataNR35(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ marginTop: '20px' }}>
                        <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                            <DatePicker label='Validade NR-35'
                                minDate={new Date('2023-01-02')}
                                onChange={(value) => setValidadeNR35(value)}
                                slotProps={{
                                    openPickerButton: {
                                        color: 'primary'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    {errorNR35 && (
                        <Box sx={{ marginLeft: '25px' }}>
                            <Typography variant='subtitle1' color='red'>
                                Por favor selecione ambas as datas!
                            </Typography>
                        </Box>
                    )}
                </Box>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Atualizar NRs</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default AtualizarNRs;
