import { React, useEffect, useState } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const AtualizarVegetacao = () => {
    const [dados, setDados] = useState([]);
    const [site, setSite] = useState('');
    const [listaSites, setListaSites] = useState([]);
    const [usinas, setUsinas] = useState('');
    const [listaUsinas, setListaUsinas] = useState([]);
    const [statusVeg, setStatusVeg] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    const listStatus = ['BAIXA', 'MÉDIA', 'ALTA'];

    useEffect(() => {
        async function buscarUsinasVegetacao () {
            const response = await fetchFlask('getUsinasVegetacao');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosSite = [...new Set(dadosFetch.map(item => item[0]))];
                setListaSites(dadosSite);
            } else {
                handleAlerta(response);
            }
        };
        buscarUsinasVegetacao();
    }, []);

    const siteSelect = (e) => {
        setSite(e.target.value);
        setUsinas([]);

        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);

        const dadosUsinas = [...new Set(dadosFiltrados.map(item => item[1]))];
        dadosUsinas.sort();
        dadosUsinas.unshift('Selecionar Todas');
        setListaUsinas(dadosUsinas);
        setUsinas([]);
    };

    const usinasChange = (e, values) => {
        if (values.includes('Selecionar Todas') && !usinas.includes('Selecionar Todas')) {
            let todasUsinas = [];
            todasUsinas = todasUsinas.concat(listaUsinas);
            todasUsinas.splice(0, 1);
            setUsinas(todasUsinas);
        } else {
            setUsinas(values);
        }
    };

    async function submitAtualizarVegetacao (e) {
        e.preventDefault();

        if (!dateTime) {
            setError(true);
            return;
        }
        setError(false);
        const data = dateTime.toISOString().slice(0, 10);

        const body = JSON.stringify({
            usina: usinas,
            statusVegetacao: statusVeg,
            dataVerificacao: data
        });

        const response = await fetchFlask('atualizarStatusVegetacao', body);
        handleAlerta(response);
        if (response.status === 'success') {
            setSite('');
            setUsinas([]);
            setStatusVeg('');
            setDateTime(null);
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Atualizar Vegetação</Typography>

            <form method='POST' id='formAtualizarVegetacao' onSubmit={submitAtualizarVegetacao}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <InputLabel id='labelSite'>Site</InputLabel>
                    <Select
                        id='selectSite'
                        labelId='labelSite'
                        label='Site'
                        value={site}
                        onChange={siteSelect}
                        sx={{ width: '300px' }}
                        required
                    >
                        {listaSites.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <Autocomplete
                        sx={{ width: '500px' }}
                        multiple
                        disableCloseOnSelect
                        id='usinas'
                        limitTags={8}
                        value={usinas}
                        options={listaUsinas}
                        onChange={usinasChange}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Usinas"
                            required={usinas.length === 0}
                            />
                        )}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='Status'>Status Vegetação</InputLabel>
                    <Select
                        id='statusVeg'
                        labelId='labelStatusVeg'
                        label='Status Vegetação'
                        sx={{ width: '300px' }}
                        value={statusVeg}
                        onChange={(e) => setStatusVeg(e.target.value)}
                        required
                    >
                        {listStatus.map((item) => (
                            <MenuItem key={item} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data Verificação'
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Atualizar Vegetação</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default AtualizarVegetacao;
