import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const CalcularAluguel = () => {
    const [cc, setCC] = useState('');
    const [dateTimePeriodo, setDateTimePeriodo] = useState(null);
    const [errorPeriodo, setErrorPeriodo] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitCalcularAluguel (e) {
        e.preventDefault();

        let periodo = '';
        if (dateTimePeriodo) {
            setErrorPeriodo(false);
            const ano = dateTimePeriodo.getFullYear();
            const mes = dateTimePeriodo.getMonth() + 1;
            periodo = ano + '-' + mes + '-1';
        } else {
            setErrorPeriodo(true);
            return;
        }

        const body = JSON.stringify({
            cc,
            periodo
        });

        const response = await fetchFlask('calcularAluguel', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setDateTimePeriodo(null);
            setErrorPeriodo(false);
        }
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Calcular Aluguel
            </Typography>

            <form action='' method='POST' id='formCalcularAluguel' onSubmit={submitCalcularAluguel}>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='CC'
                                id='cc'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={cc}
                                onChange={(e) => setCC(e.target.value)}
                            />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Período'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2023-01-01')}
                            maxDate={new Date()}
                            value={dateTimePeriodo}
                            onChange={(value) => setDateTimePeriodo(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorPeriodo && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Calcular Aluguel</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default CalcularAluguel;
