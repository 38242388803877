import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const InserirJustificativa = () => {
    const [orcamentoID, setOrcamentoID] = useState('');
    const [justificativa, setJustificativa] = useState('');
    const [message, setMessage] = useState('');
    const [statusAlerta, setStatusAlerta] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitInserirJustificativa (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            orcamentoID,
            justificativa
        });
        const response = await fetchFlask('inserirJustificativa', body);
        setMessage(response.message);
        setStatusAlerta(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setOrcamentoID('');
            setJustificativa('');
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={statusAlerta}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Inserir Justificativa
            </Typography>

            <form action='' method='POST' id='formInserirJustificativa' onSubmit={submitInserirJustificativa}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        type='number'
                        inputProps={{ min: 0 }}
                        label='Orçamento ID'
                        value={orcamentoID}
                        onChange={(e) => setOrcamentoID(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        sx={{ width: '300px' }}
                        required
                        label='Justificativa'
                        value={justificativa}
                        onChange={(e) => setJustificativa(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Inserir Justificativa</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default InserirJustificativa;
