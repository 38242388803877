import { React, useState } from 'react';

import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

import InserirGeracaoBulk from '../../components/atualizarGeracaoBulk';
import InserirGeracaoIndividual from '../../components/atualizarGeracaoIndividual';

const InserirGeracao = () => {
    const [tipo, setTipo] = useState('individual');

    return (
        <Box className='mainBox' padding={'20px'}>
            <FormControlLabel label="CSV"
                control={
                    <Switch
                        onChange={(e) => {
                            setTipo(e.target.checked ? 'bulk' : 'individual');
                        }}
                    />
                }
            />

            {tipo === 'individual' ? <InserirGeracaoIndividual /> : <InserirGeracaoBulk /> }
        </Box>
    );
};

export default InserirGeracao;
