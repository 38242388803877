import { fetchFlask } from './fetchFlask';

export const getToken = () => {
    return localStorage.getItem('loginToken');
};

export const setToken = (token) => {
    localStorage.setItem('loginToken', token);
    window.location.reload();
};

export async function removeToken () {
    const response = await fetchFlask('logout');
    if (response) {
        localStorage.removeItem('loginToken');
        window.location.reload();
    }
};
