import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import { fetchFlask } from '../../utils/fetchFlask';
import SubmitButton from '../../components/submitButton';

const AlterarVendedor = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitAlterarVendedor (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            email
        });
        const response = await fetchFlask('alterarVendedor', body);
        handleAlerta(response);
        setIsSubmit(false);
        if (response.status === 'success') {
            setEmail('');
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Alterar Vendedor Oportunidade</Typography>

            <form action='' method='POST' id='formAlterarVendedor' onSubmit={submitAlterarVendedor}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        type='email'
                        label='Email da oportunidade'
                        id='email'
                        sx={{ width: '250px' }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit} >Alterar Vendedor</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default AlterarVendedor;
