import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const GerarFechamento = () => {
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [ccsSaldo, setCCsSaldo] = useState('');

    async function submitGerarFechamento (e) {
        e.preventDefault();

        let periodo = '';
        if (dateTime) {
            setError(false);
            periodo = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            return;
        }

        const body = JSON.stringify({
            periodo,
            ccsSaldo: ccsSaldo.split('\n')
        });
        const response = await fetchFlask('gerarFechamento', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setDateTime(null);
            setCCsSaldo('');
        }
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Gerar Fechamento
            </Typography>

            <form action='' method='POST' id='formDownloadFatura' onSubmit={submitGerarFechamento}>
                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Período'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        label='Conta Contratos'
                        id='ccs'
                        required
                        multiline
                        minRows={4}
                        maxRows={10}
                        value={ccsSaldo}
                        onChange={(e) => setCCsSaldo(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Gerar Fechamento</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default GerarFechamento;
