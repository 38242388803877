import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskPhone } from '../../utils/maskCpfCnpj';

const CadastrarLeadCRM = (props) => {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [valor, setValor] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitCadastrarCRM (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            nome,
            telefone,
            email,
            valor
        });

        const response = await fetchFlask('cadastrarLeadCRM', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setNome('');
            setTelefone(null);
            setEmail('');
            setValor('');
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox' padding={'20px'}>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Grid container spacing={2}>
                <Grid item id='cadastroCRM' xs={12}>
                    <form method='POST' onSubmit={submitCadastrarCRM}>
                        <Paper elevation={3} sx={{ padding: 2, marginTop: '30px' }}>
                            <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
                                <Grid item>
                                    <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                        Cadastrar Lead no CRM
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            required
                                            label='Nome'
                                            value={nome}
                                            onChange={(e) => setNome(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            required
                                            label='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            required
                                            label='Telefone'
                                            placeholder='(XX) XXXXXXXX'
                                            value={telefone}
                                            onChange={(e) => setTelefone(maskPhone(e.target.value))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl>
                                        <TextField
                                            required
                                            label='Valor da Conta (R$)'
                                            placeholder='XXX,XX'
                                            value={valor}
                                            onChange={(e) => setValor(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl sx={{ marginTop: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SubmitButton isSubmit={isSubmit} >Cadastrar Lead</SubmitButton>
                            </FormControl>
                        </Paper>
                    </form>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CadastrarLeadCRM;
