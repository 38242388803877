import { React, useState } from 'react';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import { maskCpfCnpj } from '../utils/maskCpfCnpj';

const CheckboxInput = (props) => {
    const [checked, setChecked] = useState(false);

    return (
        <>
            <Checkbox
                onChange={(e) => {
                    setChecked(e.target.checked);
                    props.setValue('');
                }}
            />
            <TextField
                required={checked}
                disabled={!checked}
                label={props.label}
                value={props.value}
                onChange={(e) => {
                    if (props.mask === 'cpfCnpj') {
                        props.setValue(maskCpfCnpj(e.target.value));
                    } else {
                        props.setValue(e.target.value);
                    }
                }}
            />
        </>
    );
};

export default CheckboxInput;
