import { React, useEffect, useState } from 'react';

import { InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const DesfazerDistrato = () => {
    const [isCliente, setIsCliente] = useState(true);
    const [cliente, setCliente] = useState('');
    const [clientesDistrato, setClientesDistrato] = useState({});
    const [motivo, setMotivo] = useState('');
    const [unidade, setUnidade] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        async function getClientes () {
            const response = await fetchFlask('getClientesDistrato');
            if (response.status !== 'error') {
                setClientesDistrato(response.dados);
            } else {
                handleAlerta(response);
            }
        };
        getClientes();
    }, []);

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    async function submitDesfazerDistrato (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            motivo,
            unidades: isCliente ? clientesDistrato[cliente] : [unidade]
        });
        const response = await fetchFlask('desfazerDistrato', body);
        handleAlerta(response);
        setIsSubmit(false);
        if (response.status === 'success') {
            setCliente('');
            setMotivo('');
            setUnidade('');
        }

        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Desfazer Distrato
            </Typography>

            <form action='' method='POST' id='formDesfazerDistrato' onSubmit={submitDesfazerDistrato}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <FormControlLabel
                        label={isCliente ? 'Cliente' : 'Unidade'}
                        control={<Switch
                            defaultChecked
                            value={isCliente}
                            onChange={(e) => setIsCliente(!isCliente)}
                        />}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <InputLabel id='labelMotivo'>Motivo</InputLabel>
                    <Select
                        id='selectMotivo'
                        labelId='labelMotivo'
                        label='Motivo'
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                        sx={{ width: '300px' }}
                        required
                    >
                        <MenuItem value={'Inadimplência'} >
                            Inadimplência
                        </MenuItem>
                        <MenuItem value={'Troca de Titularidade'} >
                            Troca de Titularidade
                        </MenuItem>
                        <MenuItem value={'Vetado'} >
                            Vetado
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <InputLabel id='labelCliente'>Cliente</InputLabel>
                    <Select
                        id='selectCliente'
                        labelId='labelCliente'
                        label='Cliente'
                        value={cliente}
                        onChange={(e) => setCliente(e.target.value)}
                        sx={{ width: '300px' }}
                        required
                    >
                        {Object.keys(clientesDistrato).map((item) => (
                            <MenuItem key={item} value={item} >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {!isCliente && (
                    <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                        <InputLabel id='labelUnidade'>Unidade</InputLabel>
                        <Select
                            id='selectUnidade'
                            labelId='labelUnidade'
                            label='Unidade'
                            value={unidade}
                            onChange={(e) => setUnidade(e.target.value)}
                            sx={{ width: '300px' }}
                            required
                        >
                            {clientesDistrato[cliente]?.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            )) || <MenuItem disabled>Escolha um cliente</MenuItem>}
                        </Select>
                    </FormControl>
                )}

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Desfazer Distrato</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default DesfazerDistrato;
