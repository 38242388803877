import { React } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const SubmitButton = ({ isSubmit, children }) => {
    return (
        <Button
            type='submit'
            variant='contained'
            disabled={isSubmit}
            endIcon={isSubmit && <CircularProgress size={20} />}
        >
            {children}
        </Button>
    );
};

export default SubmitButton;
