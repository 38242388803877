import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const StartEC2 = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitStartEC2 (e) {
        e.preventDefault();
        setIsSubmit(true);

        const response = await fetchFlask('startEC2');
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Inicializar Servidor
            </Typography>

            <form action='' id='formStartEC2' onSubmit={submitStartEC2}>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Inicializar Servidor</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default StartEC2;
