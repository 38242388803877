import React from 'react';

import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const MenuButton = (props) => {
    return (
        <Box className='buttonBox'>
            <Link to={props.pagina}>
                <Button variant="contained">
                    {props.texto}
                </Button>
            </Link>
        </Box>
    );
};

export const MenuClickButton = (props) => {
    return (
        <Box className='buttonBox'>
            <Button onClick={props.funct} variant="contained">
                {props.texto}
            </Button>
        </Box>
    );
};
