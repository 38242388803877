import React from 'react';

import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';

import { removeToken } from '../utils/loginTokens';

function Sidebar () {
  return (
    <Drawer
    anchor="left"
    open
    variant="permanent">
      <Box>
        <List>
          <ListItem>
            <Link to="/">
              <Button>
                Menu
              </Button>
            </Link>
          </ListItem>
          <ListItem>
          <Link to="/">
              <Button onClick={removeToken}>
                Logout
              </Button>
            </Link>
          </ListItem>
        </List>
      </Box>
    </Drawer>

  );
}

export default Sidebar;
