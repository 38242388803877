import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const DesativarReativarInversor = () => {
    const [serial, setSerial] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [reativar, setReativar] = useState(false);
    const [error, setError] = useState(false);
    const [dateInversor, setDateInversor] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitDesativarInversor (e) {
        e.preventDefault();
        setIsSubmit(true);

        let data = '';
        if (dateInversor) {
            setError(false);
            data = dateInversor.toISOString().slice(0, 10);
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            reativar,
            serial,
            dateInversor: data
        });

        const response = await fetchFlask('desativarReativarInversor', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setSerial('');
            setDateInversor(null);
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                {reativar ? 'REATIVAR ' : 'DESATIVAR '}
                Inversor
            </Typography>

            <form action='' method='POST' id='formDesativarReativarInversor' onSubmit={submitDesativarInversor}>
                <FormControl sx={{ marginTop: '20px', display: 'block' }} >
                    <FormControlLabel label="Reativar Inversor"
                        control={
                            <Switch
                                checked={reativar}
                                onChange={(e) => setReativar(e.target.checked)}
                            />
                        }
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label={reativar ? 'Data Reativar' : 'Data Desativar'}
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            onChange={(value) => setDateInversor(value) }
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Serial Inversor'
                        value={serial}
                        onChange={(e) => setSerial(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>{reativar ? 'Reativar Inversor' : 'Desativar Inversor'}</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default DesativarReativarInversor;
