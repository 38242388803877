import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const UserFakePortal = () => {
    const [cc, setCC] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    async function submitUserFakePortal (e) {
        e.preventDefault();

        const body = JSON.stringify({
            cc
        });

        const response = await fetchFlask('userFakePortal', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
        }
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Copiar Usuario Portal
            </Typography>

            <form action='' method='POST' id='formCadastrarCC' onSubmit={submitUserFakePortal}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato'
                        value={cc}
                        onChange={(e) => setCC(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">Copiar Usuario</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default UserFakePortal;
