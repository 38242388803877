import { React, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const InserirProtocolos = () => {
    const [cc, setCC] = useState('');
    const [protocolo, setProtocolo] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [error, setError] = useState(false);
    const [prazo, setPrazo] = useState('');
    const [statusProtocolo, setStatusProtocolo] = useState('');
    const [natureza, setNatureza] = useState('');
    const [descricao, setDescricao] = useState('');
    const [listDescricao, setListDescricao] = useState([]);
    const [responsavel, setResponsavel] = useState('');
    const [obs, setObs] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    const selectNatureza = (e) => {
        const catAtual = e.target.value;
        setNatureza(catAtual);

        const descricoes = {
            Alteração: ['Titularidade', 'Demanda', 'Rateio', 'Enquadramento'],
            Solicitação: ['Segunda Via', 'Demonstrativo', 'Desligamento Definitivo', 'Danos Elétricos'],
            Correção: ['Faturamento', 'Injetado'],
            'Rede Celpe': ['Falta de Energia', 'Qualidade', 'Religação', 'Falta de fase',
                           'Desligamento Definitivo', 'Elo Fusível', 'Chave Faca', 'Sobretensão', 'Não Especificado']
        };
        setListDescricao(descricoes[catAtual]);
        setDescricao('');
    };

    async function inserirProtocolos (e) {
        e.preventDefault();

        let dataInicio = '';
        if (dateTime) {
            setError(false);
            dataInicio = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            return;
        }

        const body = JSON.stringify({
            cc,
            protocolo,
            dataInicio,
            prazo,
            statusProtocolo,
            natureza,
            descricao,
            responsavel,
            obs
        });
        const response = await fetchFlask('fetchInserirProtocolos', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setProtocolo('');
            setDateTime(null);
            setPrazo('');
            setStatusProtocolo('');
            setNatureza('');
            setDescricao('');
            setResponsavel('');
        }
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Inserir Protocolos
            </Typography>

            <form action='' method='POST' id='formInserirProtocolos' onSubmit={inserirProtocolos}>
                <Box className='row'>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Conta Contrato'
                                value={cc}
                                onChange={(e) => setCC(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                required
                                label='Protocolo'
                                value={protocolo}
                                onChange={(e) => setProtocolo(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ marginTop: '20px' }}>
                            <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                <DatePicker id='dataInicio' label='Data de Início'
                                    minDate={new Date('2023-01-02')}
                                    maxDate={new Date()}
                                    value={dateTime}
                                    onChange={(value) => setDateTime(value)}
                                    slotProps={{
                                        openPickerButton: {
                                            color: 'primary'
                                        }
                                    }}
                                />
                                {error && (
                                    <Typography variant='caption' color='red'>
                                        Por favor selecione uma data!
                                    </Typography>
                                )}
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Prazo (Dias)'
                                required
                                type='number'
                                inputProps={{ min: 0 }}
                                value={prazo}
                                onChange={(e) => setPrazo(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                    <Box className='column'>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelStatusProtocolo'>Status</InputLabel>
                            <Select
                                id='statusProtocolo'
                                labelId='labelStatusProtocolo'
                                label='Instância'
                                sx={{ width: '300px' }}
                                value={statusProtocolo}
                                onChange={(e) => setStatusProtocolo(e.target.value)}
                                required
                            >
                                <MenuItem value='EMAIL'>E-mail</MenuItem>
                                <MenuItem value='TN'>TN</MenuItem>
                                <MenuItem value='RECLAMAÇÃO'>Reclamação</MenuItem>
                                <MenuItem value='OUVIDORIA'>Ouvidoria</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelCat'>Natureza</InputLabel>
                            <Select
                                id='natureza'
                                labelId='labelCat'
                                label='natureza'
                                sx={{ width: '300px' }}
                                value={natureza}
                                onChange={selectNatureza}
                                required
                            >
                                <MenuItem value='Alteração'>Alteração</MenuItem>
                                <MenuItem value='Solicitação'>Solicitação</MenuItem>
                                <MenuItem value='Correção'>Correção</MenuItem>
                                <MenuItem value='Rede Celpe'>Rede Celpe</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelDescricao'>Descrição</InputLabel>
                            <Select
                                id='descriacao'
                                labelId='labelDescricao'
                                label='Descrição'
                                sx={{ width: '300px' }}
                                value={descricao}
                                onChange={(e) => setDescricao(e.target.value)}
                                required
                            >
                                {listDescricao.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelResponsavel'>Responsável</InputLabel>
                            <Select
                                id='responsavel'
                                labelId='labelResponsavel'
                                label='Responsável'
                                sx={{ width: '300px' }}
                                value={responsavel}
                                onChange={(e) => setResponsavel(e.target.value)}
                                required
                            >
                                <MenuItem value='Anny'>Anny</MenuItem>
                                <MenuItem value='Daniel'>Daniel</MenuItem>
                                <MenuItem value='Eduardo'>Eduardo</MenuItem>
                                <MenuItem value='Solluan'>Solluan</MenuItem>
                                <MenuItem value='Elisa Souza'>Elisa Souza</MenuItem>
                                <MenuItem value='Engenharia O&M'>Engenharia O&M </MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Observação'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={obs}
                                onChange={(e) => setObs(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button type='submit' variant='contained'>Inserir Protocolo</Button>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </Box>
    );
};

export default InserirProtocolos;
