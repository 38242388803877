import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import FinalizarProtocolo from '../pages/protocolos/finalizarProtocolos';
import InserirProtocolos from '../pages/protocolos/inserirProtocolos';
import ProrrogarProtocolo from '../pages/protocolos/prorrogarProtocolo';
import ProtectedRoute from '../components/protectedRoutes';

const RouteProtocolos = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Protocolos'/>}>
                <Route path='finalizarProtocolo' element={<FinalizarProtocolo/>}/>
                <Route path='inserirProtocolos' element={<InserirProtocolos/>}/>
                <Route path='prorrogarProtocolo' element={<ProrrogarProtocolo/>}/>
            </Route>
        </Routes>
    );
};

export default RouteProtocolos;
