import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj } from '../../utils/maskCpfCnpj';

const LancarAcordo = () => {
    const [documento, setDocumento] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [parcelas, setParcelas] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitLancarAcordo (e) {
        e.preventDefault();
        setIsSubmit(true);

        let vencimento = '';
        if (dateTime) {
            setError(false);
            vencimento = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            documento,
            vencimento,
            parcelas
        });

        const response = await fetchFlask('gerarLancamentosAcordo', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setDocumento('');
            setDateTime(null);
            setParcelas('');
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                LANÇAR ACORDO (BOLETOS OMIE)
            </Typography>

            <form action='' method='POST' id='formCancelarOS' onSubmit={submitLancarAcordo}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='CPF/CNPJ'
                        value={documento}
                        onChange={(e) => setDocumento(maskCpfCnpj(e.target.value))}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Número de parcelas'
                        type='number'
                        inputProps={{ step: 0.01, min: 0 }}
                        value={parcelas}
                        onChange={(e) => setParcelas(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Primeiro Vencimento'
                            minDate={new Date('2024-10-01')}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit} >LANÇAR ACORDO</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default LancarAcordo;
