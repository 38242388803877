import { React, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box, Button, FormControl, FormControlLabel, FormHelperText,
    Grid, IconButton, InputLabel, MenuItem, Paper, Select, Switch,
    TextField, Tooltip, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';
import { maskCpfCnpj, maskCEP, maskPhone } from '../../utils/maskCpfCnpj';

const createDocuments = () => {
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [clientType, setClientType] = useState('pf');
    const [clientName, setClientName] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [rg, setRG] = useState('');
    const [address, setAddress] = useState('');
    const [cep, setCep] = useState('');
    const [rgExp, setRgExp] = useState('');
    const [representativeName, setRepresentativeName] = useState('');
    const [representativeCpf, setRepresentativeCpf] = useState('');
    const [representativeAddress, setRepresentativeAddress] = useState('');
    const [representativeCep, setRepresentativeCep] = useState('');
    const [discount, setDiscount] = useState(0);
    const [contactEmail, setContactEmail] = useState('');
    const [contactName, setContactName] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [neoenergiaUF, setNeoenergiaUF] = useState('');
    const [cc, setCC] = useState('');
    const [ccAddress, setCCAddress] = useState('');
    const [consumption, setConsumption] = useState('');
    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [files, setFiles] = useState({ serasa: null, cnh: null, contrato: null, cnpj: null, neoenergiaBill: null });
    const [oppName, setOppName] = useState('');
    const [oppTelephone, setOppTelephone] = useState('');
    const [digital, setDigital] = useState(false);
    const [error, setError] = useState(false);
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const columns = [
        {
            field: 'cc',
            headerName: 'CC',
            width: 150,
            editable: true
        },
        {
            field: 'address',
            headerName: 'Endereço',
            width: 150,
            editable: true
        },
        {
            field: 'consumption',
            headerName: 'Consumo',
            type: 'number',
            width: 100,
            editable: true,
            renderCell: (params) => (
                new Intl.NumberFormat('pt-BR', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(params.value)
            )
        },
        {
            field: 'Upload',
            headerName: 'Conta de Energia',
            width: 280,
            renderCell: (params) => (
                <FormControl sx={{ display: 'flex', marginTop: '5px' }}>
                    <TextField
                        type='file'
                        size='small'
                        required={true}
                        inputProps={{ accept: 'application/pdf, image/*' }}
                        onChange={(e) => handleFileChange(e.target.files[0], params.row.id)}
                    />
                </FormControl>
            )
        },
        {
            field: ' ',
            renderCell: (params) => (
                <IconButton edge="end" onClick={() => deleteCC(params.row.id)}>
                    <DeleteIcon />
                </IconButton>
            )
        }
    ];

    const handleFileChange = (file, rowId) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === rowId ? { ...row, file } : row
            )
        );
    };

    const handleRowSelection = (newSelection) => {
        setSelectedRows(newSelection);
    };

    const deleteCC = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const processRowUpdate = (newRow) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.id === newRow.id ? newRow : row))
        );
        return newRow;
    };

    const addUnidades = () => {
        if (!cc || !ccAddress || !consumption) {
            alert('Preencha todos os campos para adicionar uma unidade!');
            return;
        }

        setRows([...rows, { id: rows.length + 1, cc: cc, address: ccAddress, consumption: consumption }]);
        setCC('');
        setCCAddress('');
        setConsumption('');
    };

    async function submitCreateDocuments(e) {
        e.preventDefault();
        setIsSubmit(true);

        const formData = new FormData();

        const units = rows.map(row => {
            const unitData = {
                cc: row.cc,
                address: row.address,
                consumption: row.consumption
            };

            if (row.file) {
                formData.append(row.cc, row.file);
            }

            return unitData;
        });

        if (files.serasa) formData.append('serasa', files.serasa);
        if (files.cnh) formData.append('cnh', files.cnh);
        if (files.contrato) formData.append('contrato', files.contrato);
        if (files.cnpj) formData.append('cnpj', files.cnpj);

        if (clientType === 'pf' && cpfCnpj.length !== 14) {
            setMessage('CPF com dígitos faltando!');
            setStatus('error');
            setOpenAlerta(true);
            setIsSubmit(false);
            return;
        }

        if (clientType === 'pj' && cpfCnpj.length !== 18) {
            setMessage('CNPJ com dígitos faltando!');
            setStatus('error');
            setOpenAlerta(true);
            setIsSubmit(false);
            return;
        }

        if (phone1.length < 14) {
            setMessage('Telefone 1 no formato inválido!');
            setStatus('error');
            setOpenAlerta(true);
            setIsSubmit(false);
            return;
        };

        if (phone2 && phone2.length < 14) {
            setMessage('Telefone 2 no formato inválido!');
            setStatus('error');
            setOpenAlerta(true);
            setIsSubmit(false);
            return;
        };

        formData.append('clientType', clientType);
        formData.append('clientName', clientName);
        formData.append('cpfCnpj', cpfCnpj);
        formData.append('maritalStatus', maritalStatus);
        formData.append('rg', `${rg} ${rgExp}`);
        formData.append('address', `${address}, ${cep}`);
        formData.append('representativeName', representativeName);
        formData.append('representativeCpf', representativeCpf);
        formData.append('representativeAddress', `${representativeAddress}, ${representativeCep}`);
        formData.append('discount', discount);
        formData.append('neoenergiaUF', neoenergiaUF);
        formData.append('contactName', contactName);
        formData.append('email', contactEmail);
        formData.append('phone1', phone1);
        formData.append('phone2', phone2);
        formData.append('oppName', oppName);
        formData.append('oppTelephone', oppTelephone);
        formData.append('units', JSON.stringify(units));

        const response = await fetchFlask('createDocuments', formData);
        handleAlerta(response);
        setIsSubmit(false);
    };

    function handleAlerta(response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    async function submitCartaoCNpj(e) {
        e.preventDefault();
        setIsSubmit(true);
        const formData = new FormData();

        if (files.cnpj) {
            formData.append('cnpj', files.cnpj);
        } else {
            setMessage('Faça o upload do cartão CNPJ');
            setStatus('error');
            setOpenAlerta(true);
            return;
        }

        const response = await fetchFlask('getCnpjData', formData);
        if (response.status === 'success') {
            setClientName(response.name);
            setAddress(response.address);
            setCpfCnpj(response.cnpj);
            setCep(response.cep);
        } else {
            handleAlerta(response);
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Criar Documentos Comercial</Typography>

            <form action='' method='POST' id='formNotificarCliente' onSubmit={submitCreateDocuments}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelClientType'>Tipo</InputLabel>
                            <Select
                                id='selectClientType'
                                labelId='labelClientType'
                                label='clientType'
                                value={clientType}
                                onChange={(e) => setClientType(e.target.value)}
                                sx={{ width: '220px' }}
                            >
                                <MenuItem value={'pf'}>Pessoa Física</MenuItem>
                                <MenuItem value={'pj'}>Pessoa Jurídica</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {clientType && (
                        <>
                            <Grid item xs={5}>
                                <Paper elevation={3} sx={{ padding: 2, margin: '10px', marginTop: '20px' }}>
                                    <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                        Cliente
                                    </Typography>

                                    {clientType === 'pj' && (
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: 'block', marginBottom: '10px', marginTop: '10px' }}>
                                                <Typography variant='h6'>Cartão CNPJ</Typography>
                                                <TextField
                                                    type='file'
                                                    size='small'
                                                    inputProps={{ accept: '*/*' }}
                                                    onChange={(e) => setFiles({ ...files, cnpj: e.target.files[0] })}
                                                />
                                            </FormControl>

                                            <Button
                                                disabled={isSubmit}
                                                onClick={submitCartaoCNpj}
                                                endIcon={isSubmit && <CircularProgress size={20} />}
                                            >
                                                Buscar dados
                                            </Button>
                                        </Grid>
                                    )}

                                    <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
                                        <Grid item xs={12}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Nome do Cliente'
                                                    value={clientName}
                                                    onChange={(e) => setClientName(e.target.value)}
                                                    disabled={clientType === 'pj'}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='CPF ou CNPJ'
                                                    value={cpfCnpj}
                                                    inputProps={{ maxLength: clientType === 'pf' ? 14 : 18 }}
                                                    onChange={(e) => setCpfCnpj(maskCpfCnpj(e.target.value))}
                                                    disabled={clientType === 'pj'}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='RG'
                                                    value={rg}
                                                    onChange={(e) => setRG(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Orgão Expedidor'
                                                    value={rgExp}
                                                    onChange={(e) => setRgExp(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={8}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Endereço'
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    disabled={clientType === 'pj'}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='CEP'
                                                    value={cep}
                                                    inputProps={{ maxLength: 9 }}
                                                    onChange={(e) => setCep(maskCEP(e.target.value))}
                                                    disabled={clientType === 'pj'}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <InputLabel>Estado Civil</InputLabel>
                                                <Select
                                                    value={maritalStatus}
                                                    label="Estado Civil"
                                                    onChange={(e) => setMaritalStatus(e.target.value)}
                                                >
                                                    <MenuItem value="casado">Casado</MenuItem>
                                                    <MenuItem value="solteiro">Solteiro</MenuItem>
                                                    <MenuItem value="união estável">União Estável</MenuItem>
                                                    <MenuItem value="divorciado">Divorciado</MenuItem>
                                                    <MenuItem value="viúvo">Viúvo</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={5}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <InputLabel id='labelDistribuidora'>Distribuidora</InputLabel>
                                                <Select
                                                    id='selectDistribuidora'
                                                    labelId='labelDistribuidora'
                                                    label='Distribuidora'
                                                    value={neoenergiaUF}
                                                    required
                                                    onChange={(e) => setNeoenergiaUF(e.target.value)}
                                                >
                                                    <MenuItem value={'PE'}>NEOENERGIA PE</MenuItem>
                                                    <MenuItem value={'RN'}>COSERN</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                <TextField
                                                    required
                                                    label='Desconto'
                                                    value={discount}
                                                    inputProps={{ maxLength: 2 }}
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        const numericValue = parseInt(value, 10);

                                                        if ((numericValue >= 20 && numericValue <= 40) || value === '') {
                                                            setError(false);
                                                        } else {
                                                            setError(true);
                                                        }

                                                        setDiscount(numericValue);
                                                    }}
                                                />
                                                {error && (
                                                    <FormHelperText sx={{ textAlign: 'center', color: 'red', margin: '0px' }}>
                                                        Informe um desconto entre 20 e 40.
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                    </Grid>

                                    {clientType !== 'pf' && (
                                        <>
                                            <Paper elevation={2} sx={{ padding: 2, marginTop: '20px' }}>
                                                <Typography variant='h5' sx={{ marginBottom: '10px' }}>
                                                    Representante
                                                </Typography>

                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome do Representante'
                                                        value={representativeName}
                                                        onChange={(e) => setRepresentativeName(e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='CPF do Representante'
                                                        value={representativeCpf}
                                                        inputProps={{ maxLength: 14 }}
                                                        onChange={(e) => setRepresentativeCpf(maskCpfCnpj(e.target.value))}
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Endereço do Representante'
                                                        value={representativeAddress}
                                                        onChange={(e) => setRepresentativeAddress(e.target.value)}
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='CEP do Representante'
                                                        value={representativeCep}
                                                        inputProps={{ maxLength: 9 }}
                                                        onChange={(e) => setRepresentativeCep(maskCEP(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Paper>
                                        </>
                                    )}

                                    <Paper elevation={2} sx={{ padding: 2, marginTop: '20px' }}>
                                        <Typography variant='h5'>
                                            Contato
                                        </Typography>

                                        <Grid container>
                                            <Grid item xs={10}>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Nome'
                                                        value={contactName}
                                                        onChange={(e) => setContactName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={10}>
                                                <FormControl sx={{ display: 'flex', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        type="email"
                                                        label='Email'
                                                        value={contactEmail}
                                                        onChange={(e) => setContactEmail(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        required
                                                        label='Telefone 1'
                                                        inputProps={{ maxLength: 15 }}
                                                        value={phone1}
                                                        onChange={(e) => setPhone1(maskPhone(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl sx={{ display: 'block', marginTop: '10px' }}>
                                                    <TextField
                                                        label='Telefone 2'
                                                        inputProps={{ maxLength: 15 }}
                                                        value={phone2}
                                                        onChange={(e) => setPhone2(maskPhone(e.target.value))}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </Paper>

                                    <Paper elevation={2} sx={{ padding: 2, marginTop: '20px' }}>
                                        <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                            Documentos
                                        </Typography>
                                        <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center' }}>
                                            <Grid item xs={10}>
                                                <FormControl sx={{ display: 'block', marginBottom: '10px' }}>
                                                    <Typography variant='h6' sx={{ textAlign: 'center' }}>Serasa</Typography>
                                                    <TextField
                                                        type='file'
                                                        size='small'
                                                        required={true}
                                                        inputProps={{ accept: '*/*' }}
                                                        onChange={(e) => setFiles({ ...files, serasa: e.target.files[0] })}
                                                    />
                                                </FormControl>

                                                <FormControl sx={{ display: 'block', marginBottom: '10px' }}>
                                                    <Typography variant='h6' sx={{ textAlign: 'center' }}>CNH</Typography>
                                                    <TextField
                                                        type='file'
                                                        size='small'
                                                        required={true}
                                                        inputProps={{ accept: '*/*' }}
                                                        onChange={(e) => setFiles({ ...files, cnh: e.target.files[0] })}
                                                    />
                                                </FormControl>

                                                {clientType !== 'pf' && (
                                                    <>
                                                        <FormControl sx={{ display: 'block', marginBottom: '10px' }}>
                                                            <Typography variant='h6' sx={{ textAlign: 'center' }}>Contrato Social</Typography>
                                                            <TextField
                                                                type='file'
                                                                size='small'
                                                                inputProps={{ accept: '*/*' }}
                                                                onChange={(e) => setFiles({ ...files, contrato: e.target.files[0] })}
                                                            />
                                                        </FormControl>

                                                        <FormControl sx={{ display: 'block', marginBottom: '10px' }}>
                                                            <Typography variant='h6' sx={{ textAlign: 'center' }}>Cartão CNPJ</Typography>
                                                            <TextField
                                                                type='file'
                                                                size='small'
                                                                inputProps={{ accept: '*/*' }}
                                                                onChange={(e) => setFiles({ ...files, cnpj: e.target.files[0] })}
                                                            />
                                                        </FormControl>
                                                    </>
                                                )}

                                                <FormControlLabel
                                                    label="Venda Digital"
                                                    control={
                                                        <Switch
                                                            onChange={(e) => {
                                                                setDigital(!digital);
                                                            }}
                                                        />
                                                    }
                                                    sx={{ marginTop: '30px' }}
                                                />
                                                {digital &&
                                                    <>
                                                        <FormControl fullWidth>
                                                            <Typography variant='h6' sx={{ textAlign: 'center' }}>CRM</Typography>
                                                            <TextField
                                                                label='Nome da Oportunidade'
                                                                value={oppName}
                                                                onChange={(e) => setOppName(e.target.value)}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth sx={{ marginTop: '10px' }}>
                                                            <TextField
                                                                label='Telefone da Oportunidade'
                                                                value={oppTelephone}
                                                                onChange={(e) => setOppTelephone(e.target.value)}
                                                            />
                                                        </FormControl>
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Paper>
                            </Grid>

                            <Grid item xs={7}>
                                <Paper elevation={3} sx={{ padding: 2, margin: '20px' }}>
                                    <Grid container alignItems="center" justifyContent="space-between" marginBottom={2}>
                                        <Grid item>
                                            <Typography variant='h3' sx={{ marginBottom: '10px' }}>
                                                Unidades
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Paper elevation={1} sx={{ backgroundColor: '#f0f1f383', marginTop: '10px', width: '100%' }}>
                                        <Box>
                                            <DataGrid
                                                rows={rows}
                                                columns={columns}
                                                processRowUpdate={processRowUpdate}
                                                onSelectionModelChange={handleRowSelection}
                                                localeText={{ noRowsLabel: 'Sem Unidades' }}
                                                hideFooter
                                                autoHeight
                                            />
                                        </Box>
                                        {selectedRows.length > 0 && (
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => deleteCC(selectedRows)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center' }}>

                                            <Grid item>
                                                <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                                                    <TextField
                                                        label='Conta Contrato'
                                                        value={cc}
                                                        onChange={(e) => setCC(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label='Consumo'
                                                        value={consumption}
                                                        onChange={(e) => setConsumption(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        label='Endereço'
                                                        value={ccAddress}
                                                        onChange={(e) => setCCAddress(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Button variant='contained' onClick={addUnidades}>Adicionar Unidade</Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <Grid container spacing={2} sx={{ padding: 2, justifyContent: 'center', marginTop: '50px', marginBottom: '50px' }}>
                                        <Grid item xs={8}>
                                            <FormControl sx={{ display: 'flex' }}>
                                                <SubmitButton isSubmit={isSubmit} >Criar Documentos</SubmitButton>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            </Grid>
                        </>
                    )}

                </Grid>
            </form>
        </Box>
    );
};

export default createDocuments;
