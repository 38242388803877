import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import styles from '../pages/css/geracaoCSS.module.css';
import { fetchFlask } from '../utils/fetchFlask';
import { Alerta } from '../components/alerta';

const InserirFalhaIndividual = () => {
    const [dateTime, setDateTime] = useState(null);
    const [site, setSite] = useState('');
    const [listaSites, setListaSites] = useState([]);
    const [usinas, setUsinas] = useState([]);
    const [listaUsinas, setListaUsinas] = useState([]);
    const [inversores, setInversores] = useState([]);
    const [listaInv, setListaInv] = useState([]);
    const [dados, setDados] = useState([]);
    const [natureza, setNatureza] = useState('');
    const [categoria, setCategoria] = useState('');
    const [listaCat, setListaCat] = useState([]);
    const [obs, setObs] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    useEffect(() => {
        async function pegarDados () {
            const response = await fetchFlask('pegarDados');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosSite = [...new Set(dadosFetch.map(item => item[0]))];
                setListaSites(dadosSite);
            } else {
                handleAlerta(response);
            }
        };
        pegarDados();
    }, []);

    const selectSite = (e) => {
        setSite(e.target.value);
        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);
        const dadosusinas = [...new Set(dadosFiltrados.map(item => item[1]))];
        dadosusinas.sort();
        dadosusinas.unshift('Selecionar Todas');
        setListaUsinas(dadosusinas);
        setUsinas([]);
        setInversores([]);
    };

    const usinasChange = (e, values) => {
        let usinasSeleciondas;
        if (values.includes('Selecionar Todas') && !usinas.includes('Selecionar Todas')) {
            let todasUsinas = [];
            todasUsinas = todasUsinas.concat(listaUsinas);
            todasUsinas.splice(0, 1);
            setUsinas(todasUsinas);
            usinasSeleciondas = todasUsinas;
        } else {
            setUsinas(values);
            usinasSeleciondas = values;
        }

        const dadosFiltrados = dados.filter(item => usinasSeleciondas.includes(item[1]));
        const dadosInv = dadosFiltrados.map(item => item[2]);
        dadosInv.sort();
        dadosInv.unshift('Selecionar Todos');
        setListaInv(dadosInv);
        setInversores([]);
    };

    const inversoresChange = (e, values) => {
        if (values.includes('Selecionar Todos') && !inversores.includes('Selecionar Todos')) {
            let todosInv = [];
            todosInv = todosInv.concat(listaInv);
            todosInv.splice(0, 1);
            setInversores(todosInv);
        } else {
            setInversores(values);
        }
    };

    const naturezaChange = (e) => {
        const naturezaAtual = e.target.value;
        setNatureza(naturezaAtual);

        const categorias = {
            Distribuidora: [
                'Interrupção de Fornecimento', 'Rompimento de Elo Fusível', 'Sobretensão', 'Subtensão',
                'Sobrecorrente', 'Variação de Frequência'],
            Clima: ['Chuva', 'Nublado', 'Vento Forte', 'Sombreamento'],
            'Interna Inversor': ['Anomalia PV', 'Relé de Proteção', 'DPS', 'Ventilador', 'RISO CA', 'RISO CC'],
            Dispositivos: ['Disjuntor BT', 'Disjuntor MT', 'DPS CA', 'DPS CC', 'Otimizador', 'Rele Temperatura', 'Módulo', 'Transformador'],
            Manutenção: ['Corretiva', 'Preventiva', 'Preditiva'],
            Tracker: ['TCU', 'NCU'],
            Externa: ['Clientes', 'Roubo']
        };
        setListaCat(categorias[naturezaAtual]);
    };

    async function submitFalhas (e) {
        e.preventDefault();

        let data = '';
        if (dateTime) {
            setError(false);
            data = dateTime.toISOString().slice(0, 10);
        } else {
            setError(true);
            return;
        }
        setError(false);

        const body = JSON.stringify({
            data,
            inversores,
            natureza,
            categoria,
            obs
        });
        const response = await fetchFlask('inserirFalhas', body);
        handleAlerta(response);
        if (response.status === 'success') {
            setUsinas([]);
            setInversores([]);
        }
    }

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    return (
        <>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>Inserir Falhas</Typography>
            <form action='' method='POST' id='formFalhas' onSubmit={submitFalhas}>
                <Box className={styles.row}>
                    <Box className={styles.column}>
                        <FormControl sx={{ marginTop: '20px' }} >
                            <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                                <DatePicker id='data' label='Dia da Falha'
                                    minDate={new Date('2023-01-02')}
                                    maxDate={new Date()}
                                    onChange={(value) => setDateTime(value)}
                                    slotProps={{
                                        openPickerButton: {
                                            color: 'primary'
                                        }
                                    }}
                                />
                                {error && (
                                    <Typography variant='caption' color='red'>
                                        Por favor selecione uma data!
                                    </Typography>
                                )}
                            </LocalizationProvider>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <InputLabel id='labelSite'>Site</InputLabel>
                            <Select
                                id='selectSite'
                                labelId='labelSite'
                                label='Site'
                                value={site}
                                onChange={selectSite}
                                sx={{ width: '300px' }}
                                required
                            >
                                {listaSites.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <Autocomplete
                                sx={{ width: '500px' }}
                                multiple
                                disableCloseOnSelect
                                id='usinas'
                                limitTags={8}
                                value={usinas}
                                options={listaUsinas}
                                onChange={usinasChange}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label='Usinas'
                                    required={usinas.length === 0}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                            <Autocomplete
                                sx={{ width: '500px' }}
                                multiple
                                disableCloseOnSelect
                                id='inversores'
                                limitTags={8}
                                value={inversores}
                                options={listaInv}
                                onChange={inversoresChange}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label='Inversores'
                                    required={inversores.length === 0}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <Box className={styles.column}>
                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelNatureza'>Natureza</InputLabel>
                            <Select
                                labelId='labelNatureza'
                                label='Natureza'
                                sx={{ width: '300px' }}
                                value={natureza}
                                onChange={naturezaChange}
                                required
                            >
                                <MenuItem value='Distribuidora' >Distribuidora</MenuItem>
                                <MenuItem value='Clima' >Clima</MenuItem>
                                <MenuItem value='Interna Inversor' >Interna Inversor</MenuItem>
                                <MenuItem value='Dispositivos' >Dispositivos</MenuItem>
                                <MenuItem value='Manutenção' >Manutenção</MenuItem>
                                <MenuItem value='Tracker' >Tracker</MenuItem>
                                <MenuItem value='Externa' >Externa</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <InputLabel id='labelCategoria'>Categoria</InputLabel>
                            <Select
                                labelId='labelCategoria'
                                label='Categoria'
                                sx={{ width: '300px' }}
                                value={categoria}
                                required
                                onChange={(e) => setCategoria(e.target.value)}
                            >
                                {listaCat.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <TextField
                                label='Observação'
                                id='obs'
                                multiline
                                maxRows={4}
                                sx={{ width: '300px' }}
                                value={obs}
                                onChange={(e) => setObs(e.target.value)}
                            />
                        </FormControl>

                        <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Button type='submit' variant='contained' sx={{ '&.hover': { backgroundColor: 'red' } }} >Inserir Falha</Button>
                        </FormControl>
                    </Box>
                </Box>
            </form>
        </>
    );
};

export default InserirFalhaIndividual;
