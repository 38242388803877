import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import ChangeSchedule from '../pages/contabilidade/changeSchedule';
import StartEC2 from '../pages/contabilidade/startEC2';

const RouteContabilidade = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Contabilidade'/>}>
                <Route path='startEC2' element={<StartEC2/>}/>
                <Route path='changeSchedule' element={<ChangeSchedule/>}/>
            </Route>
        </Routes>
    );
};

export default RouteContabilidade;
