import { React, useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const GerarPix = () => {
    const [cc, setCC] = useState('');
    const [dateTime, setDateTime] = useState(null);
    const [dateTimePag, setDateTimePag] = useState(null);
    const [error, setError] = useState(false);
    const [errorPag, setErrorPag] = useState(false);
    const [valorConta, setValorConta] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    async function submitGerarPix (e) {
        e.preventDefault();
        setIsSubmit(true);

        let periodo = '';
        if (dateTime) {
            setError(false);
            const ano = dateTime.getFullYear();
            const mes = dateTime.getMonth() + 1;
            periodo = ano + '-' + mes;
        } else {
            setError(true);
            setIsSubmit(false);
            return;
        }

        let dataPagamento = '';
        if (dateTimePag) {
            setErrorPag(false);
            dataPagamento = dateTimePag.toISOString().slice(0, 10);
        } else {
            setErrorPag(true);
            setIsSubmit(false);
            return;
        }

        const body = JSON.stringify({
            cc,
            periodo,
            valor: valorConta,
            pagamento: dataPagamento
        });

        const response = await fetchFlask('gerarPix', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setDateTime(null);
            setDateTimePag(null);
            setValorConta('');
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                GERAR PIX - BOLETO BAIXADO
            </Typography>

            <form action='' method='POST' id='formCancelarOS' onSubmit={submitGerarPix}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato'
                        value={cc}
                        onChange={(e) => setCC(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Período'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2024-01-01')}
                            maxDate={new Date()}
                            value={dateTime}
                            onChange={(value) => setDateTime(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {error && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Valor Original do Boleto'
                        type='number'
                        inputProps={{ step: 0.01, min: 0 }}
                        value={valorConta}
                        onChange={(e) => setValorConta(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data de Pagamento'
                            minDate={new Date('2024-06-01')}
                            value={dateTimePag}
                            onChange={(value) => setDateTimePag(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorPag && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit} >GERAR PIX</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default GerarPix;
