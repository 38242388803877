import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import AlertasPVOperation from '../pages/oem/alertasPVOperation';
import AtualizarExtintores from '../pages/oem/atualizarExtintores';
import AtualizarNRs from '../pages/oem/atualizarNrs';
import AtualizarVegetacao from '../pages/oem/atualizarVegetacao';
import DesativarReativarInversor from '../pages/oem/desativarReativarInversor';
import InserirFalhas from '../pages/oem/inserirFalhas';
import InserirGeracao from '../pages/oem/inserirGeracao';
import RelatorioSPEs from '../pages/oem/relatorioSPEs';
import SubstituirInversor from '../pages/oem/substituirInversor';
import ProtectedRoute from '../components/protectedRoutes';

const RouteOem = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='OeM'/>}>
                <Route path='alertasPVOperation' element={<AlertasPVOperation/>}/>
                <Route path='atualizarExtintores' element={<AtualizarExtintores/>}/>
                <Route path='atualizarNRs' element={<AtualizarNRs/>}/>
                <Route path='atualizarVegetacao' element={<AtualizarVegetacao/>}/>
                <Route path='/inserirFalhas' element={<InserirFalhas/>}/>
                <Route path='inserirGeracao' element={<InserirGeracao/>}/>
                <Route path='criarRelatorioSPEs' element={<RelatorioSPEs/>}/>
                <Route path='substituirInversor' element={<SubstituirInversor/>}/>
                <Route path='desativarReativarInversor' element={<DesativarReativarInversor/>}/>
            </Route>
        </Routes>
    );
};

export default RouteOem;
