import { React, useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import './fonts/gotham/GothamBold.ttf';
import './fonts/gotham/GothamBook.ttf';
import './fonts/gotham/GothamBookItalic.ttf';
import './pages/css/index.css';

import circularProgress from './components/circularProgress';
import Sidebar from './components/sidebar';
import Login from './pages/login';
import Menu from './pages/menu';
import Unauthorized from './pages/unauthorized';
import RouteComercial from './routes/comercial';
import RouteAddCliente from './routes/comercialInterno';
import RouteConsultor from './routes/consultor';
import RouteContabilidade from './routes/contabilidade';
import RouteExterno from './routes/externo';
import RouteFinanceiro from './routes/financeiro';
import RouteGE from './routes/gestaoEnergia';
import RouteOem from './routes/oem';
import RouteProtocolos from './routes/protocolos';
import RouteSettaServicos from './routes/settaServicos';
import { theme } from './theme';
import DadosUsuario from './utils/context';
import { fetchFlask } from './utils/fetchFlask';
import { getToken } from './utils/loginTokens';

const App = () => {
    const [autorizacoes, setAutorizacoes] = useState([]);
    const [email, setEmail] = useState('');
    const loginToken = getToken();

    useEffect(() => {
        async function fetchAutorizacao () {
            const response = await fetchFlask('autorizacao');
            if (response.autorizacao === undefined) {
                alert(response.message);
            } else {
                setAutorizacoes(response.autorizacao);
                setEmail(response.email);
            }
        };
        if (loginToken) {
            fetchAutorizacao();
        }
    }, []);

    if (email === '' && loginToken !== null) {
        return circularProgress();
    }

    return (
        <DadosUsuario.Provider value={{ autorizacoes, email }}>
            <Router>
                {loginToken === null
                    ? <Login/>
                    : (<>
                        <ThemeProvider theme={theme}>
                            <Sidebar />
                            <Routes>
                                <Route exact path='/' element={<Menu/>}/>
                                <Route path='/OeM/*' element={<RouteOem/>} />
                                <Route path='/Protocolos/*' element={<RouteProtocolos/>} />
                                <Route path='/GestaoEnergia/*' element={<RouteGE/>} />
                                <Route path='/Financeiro/*' element={<RouteFinanceiro/>} />
                                <Route path='/Comercial/*' element={<RouteComercial/>} />
                                <Route path='/Externo/*' element={<RouteExterno/>} />
                                <Route path='/Contabilidade/*' element={<RouteContabilidade/>} />
                                <Route path='/Interno/*' element={<RouteAddCliente/>} />
                                <Route path='/Consultor/*' element={<RouteConsultor/>} />
                                <Route path='/settaServicos/*' element={<RouteSettaServicos/>} />
                                <Route path='/403-Unauthorized' element={<Unauthorized/>} />
                            </Routes>
                        </ThemeProvider>
                    </>)
                }
            </Router>
        </DadosUsuario.Provider>
    );
};

export default App;
