import { React, useState, useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';

const AluguelMataraca = () => {
    const [cc, setCC] = useState('');
    const [contaEnergia, setContaEnergia] = useState(null);
    const [dateTimePeriodo, setDateTimePeriodo] = useState(null);
    const [errorPeriodo, setErrorPeriodo] = useState(false);
    const [dateTimeVencimento, setDateTimeVencimento] = useState(null);
    const [errorVencimento, setErrorVencimento] = useState(false);
    const [dateTimeDataInicial, setDateTimeDataInicial] = useState(null);
    const [errorDateTimeDataInicial, setErrorDateTimeDataInicial] = useState(false);
    const [dateTimeDataFinal, setDateTimeDataFinal] = useState(null);
    const [errorDateTimeDataFinal, setErrorDateTimeDataFinal] = useState(false);
    const [valor, setValor] = useState('');
    const [consumo, setConsumo] = useState('');
    const [ajuste, setAjuste] = useState('');
    const [injetado, setInjetado] = useState('');
    const [tarifa, setTarifa] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);

    const contaRef = useRef(null);

    async function submitAluguelMataraca (e) {
        e.preventDefault();
        let periodo = '';
        if (dateTimePeriodo) {
            setErrorPeriodo(false);
            const ano = dateTimePeriodo.getFullYear();
            const mes = dateTimePeriodo.getMonth() + 1;
            periodo = ano + '-' + mes;
        } else {
            setErrorPeriodo(true);
            return;
        }

        let vencimento = '';
        if (dateTimeVencimento) {
            setErrorVencimento(false);
            vencimento = dateTimeVencimento.toISOString().slice(0, 10);
        } else {
            setErrorVencimento(true);
            return;
        }

        let dataFinal = '';
        if (dateTimeDataFinal) {
            setErrorDateTimeDataFinal(false);
            dataFinal = dateTimeDataFinal.toISOString().slice(0, 10);
        } else {
            setErrorDateTimeDataFinal(true);
            return;
        }

        let dataInicial = '';
        if (dateTimeDataInicial) {
            setErrorDateTimeDataInicial(false);
            dataInicial = dateTimeDataInicial.toISOString().slice(0, 10);
        } else {
            setErrorDateTimeDataInicial(true);
            return;
        }
        const formData = new FormData();
        formData.append('cc', cc);
        formData.append('valor', valor);
        formData.append('consumo', consumo);
        formData.append('ajuste', ajuste);
        formData.append('tarifa', tarifa);
        formData.append('injetado', injetado);
        formData.append('periodo', periodo);
        formData.append('vencimento', vencimento);
        formData.append('data_inicial', dataInicial);
        formData.append('data_final', dataFinal);
        formData.append('contaEnergia', contaEnergia);

        const response = await fetchFlask('aluguelMataraca', formData);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setCC('');
            setDateTimePeriodo('');
            setDateTimePeriodo('');
            setDateTimeDataFinal('');
            setDateTimeDataInicial('');
            setValor('');
            setConsumo('');
            setAjuste('');
            setInjetado('');
            setTarifa('');
            setContaEnergia(null);
            contaRef.current.value = '';
        }
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Aluguel de Mataraca PB
            </Typography>

            <form action='' method='POST' id='formAluguelMataraca' onSubmit={submitAluguelMataraca}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Conta Contrato'
                        value={cc}
                        onChange={(e) => setCC(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }}>
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Período'
                            views={['month', 'year']}
                            openTo='month'
                            minDate={new Date('2023-01-02')}
                            maxDate={new Date()}
                            value={dateTimePeriodo}
                            onChange={(value) => setDateTimePeriodo(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorPeriodo && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Vencimento'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeVencimento}
                            onChange={(value) => setDateTimeVencimento(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorVencimento && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data Inicial'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeDataInicial}
                            onChange={(value) => setDateTimeDataInicial(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorDateTimeDataInicial && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data Final'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeDataFinal}
                            onChange={(value) => setDateTimeDataFinal(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorDateTimeDataFinal && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Valor'
                        value={valor}
                        onChange={(e) => setValor(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Consumo'
                        value={consumo}
                        onChange={(e) => setConsumo(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Ajuste'
                        value={ajuste}
                        onChange={(e) => setAjuste(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Injetado'
                        value={injetado}
                        onChange={(e) => setInjetado(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Tarifa'
                        value={tarifa}
                        onChange={(e) => setTarifa(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                            <Typography>Conta de Energia</Typography>
                            <TextField
                                type='file'
                                size='small'
                                inputRef={contaRef}
                                inputProps={{ accept: 'application/pdf, image/*' }}
                                onChange={(e) => setContaEnergia(e.target.files[0])}
                            />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Button type='submit' variant="contained">CALCULAR ALUGUEL</Button>
                </FormControl>
            </form>
        </Box>
    );
};

export default AluguelMataraca;
