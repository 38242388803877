import { React } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { MenuButton } from '../components/menuButton';

const Unauthorized = () => {
    return (
        <Box sx={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
            <Typography variant='h3' color='red'>Erro: 403</Typography>

            <Typography>Você não tem autorização para visualizar essa página</Typography>

            <MenuButton pagina='/' texto='Menu'/>
        </Box>
    );
};

export default Unauthorized;
