import { React } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const Alerta = (props) => {
    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            props.handleClose();
        }
    };

    const handleStatus = () => {
        if (props.status === '') {
            return 'warning';
        }
            return props.status;
    };

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open} onClose={handleClose}>
            <Alert variant='filled' severity={handleStatus()} onClose={handleClose}>
                {props.texto}
            </Alert>
        </Snackbar>
    );
};

export const PopupDialog = (props) => {
    function handleConfirmar (e) {
        e.preventDefault();
        props.funcao();
        props.handleClose();
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>
                {props.titulo}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.texto}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => { handleConfirmar(e); }}>Confirmar</Button>
                <Button onClick={props.handleClose}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
};
