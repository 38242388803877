import { React } from 'react';

import Box from '@mui/material/Box';

import ComercialCRM from '../../components/comercialCRM';

const PropostaComercial = () => {
    return (
        <Box className='mainBox' padding={'20px'}>
            <ComercialCRM />
        </Box>
    );
};

export default PropostaComercial;
